import {KTSVG} from '_metronic/helpers'
import {getDeviceTypeIcon, getDeviceTypeLabel} from 'common/helpers'
import {FC, useMemo} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  stats: [{type: number; count: number}]
} & React.HTMLAttributes<HTMLDivElement>

const Platform: FC<Props> = ({stats, ...props}) => {
  const intl = useIntl()
  const total = useMemo(() => stats.reduce((acc, curr) => acc + curr.count, 0), [stats])

  const color = 'success'

  return (
    <div className={`card card-xl-stretch mb-xl-8`} {...props}>
      <div className='card-body p-0'>
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bolder fs-3'>Platform Stats</h3>
          </div>

          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-bold fs-7'>Total Devices</span>
            <span className='fw-bolder fs-2x pt-1'>{total.toLocaleString()}</span>
          </div>
        </div>

        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
          style={{marginTop: '-100px'}}
        >
          {stats.map((stat, index) => (
            <div className='d-flex align-items-center mb-6' key={index}>
              <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                  <KTSVG path={getDeviceTypeIcon(stat.type)} className='svg-icon-1' />
                </span>
              </div>

              <div className='d-flex align-items-center flex-wrap w-100'>
                <div className='mb-1 pe-3 flex-grow-1'>
                  <span className='fs-3 text-gray-800 text-hover-primary fw-bolder'>
                    {intl.$t({id: getDeviceTypeLabel(stat.type)})}
                  </span>
                </div>

                <div className='d-flex align-items-center'>
                  <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                    {stat.count.toLocaleString()}
                  </div>
                </div>
                {/* end::Label */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Platform
