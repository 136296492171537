import {GET_ONLINE_USER_STATS} from './ActionTypes/ApiActionTypes'

export const getOnlineUserStats = (apiKey, onSuccess, onFailure) => {
  return {
    type: GET_ONLINE_USER_STATS,
    payload: {
      apiUrl: 'school/stats/online-users',
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      header2: {
        'x-api-key': apiKey,
      },
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}
