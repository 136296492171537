import {KTCard, KTCardBody} from '_metronic/helpers'
import Logo from 'common/components/Logo'
import Spinner from 'common/components/Spinner'
import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import {getOnlineUserStats} from 'setup/redux/Actions/School/Stats/stats'
import DailyChart from './components/DailyChart'
import Platform from './components/Platform'
import BranchWiseOnlineUsers from './components/BranchWiseOnlineUsers'
import Swal from 'sweetalert2'
import {Alert} from 'react-bootstrap'
import StudentParentPlatform from './components/StudentParentPlatform'
import RegisteredUsers from './components/RegisteredUsers'
import OnlineUsers from './components/OnlineUsers'

const Stats = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const [apiKey, setApiKey] = useState('')
  const [loading, setLoading] = useState(true)
  const [stats, setStats] = useState<any>([])
  const [dailyPlatformSeries, setDailyPlatformSeries] = useState<
    ApexAxisChartSeries | ApexNonAxisChartSeries
  >([])

  const loadStats = useCallback(() => {
    setLoading(true)

    const onSuccess = (res: any) => {
      setStats(res.result)
      setDailyPlatformSeries([
        {
          name: 'Logged In Users',
          data: res.result.dailyStats.map((stat: any) => ({
            x: stat.date,
            y: stat.count,
          })),
        },
      ])
      setLoading(false)
    }

    const onFailure = (err: any) => {
      toast.error(intl.$t({id: 'GENERAL.SOMETHING_WENT_WRONG'}))
      setLoading(false)
    }

    dispatch(getOnlineUserStats(apiKey, onSuccess, onFailure))
  }, [apiKey, dispatch, intl])

  useEffect(() => {
    Swal.fire({
      title: 'Enter API Key',
      input: 'text',
      inputPlaceholder: 'Your API Key',
      showCancelButton: false,
      confirmButtonText: 'Submit',
      allowOutsideClick: false,
      preConfirm: (key) => {
        if (!key) {
          Swal.showValidationMessage('API Key is required')
        }
        return key
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setApiKey(result.value)
      }
    })
  }, [])

  useEffect(() => {
    if (apiKey) {
      loadStats()
    }
  }, [apiKey, loadStats])

  if (!apiKey) {
    return (
      <div style={{height: '90vh'}}>
        <KTCard className='h-100'>
          <KTCardBody>
            <Alert variant='danger'>
              <Alert.Heading>API Key is required</Alert.Heading>
              <p>Please enter your API Key to continue</p>
            </Alert>
          </KTCardBody>
        </KTCard>
      </div>
    )
  }

  return (
    <>
      <div className='center mb-5'>
        <Logo showBranchLogo={false} />
      </div>

      {loading && (
        <KTCard>
          <KTCardBody>
            <div className='center p-5'>
              <Spinner color='primary' />
            </div>
          </KTCardBody>
        </KTCard>
      )}

      {!loading && (
        <div className='row gy-5'>
          <div className='col-md-6'>
            <RegisteredUsers stats={stats.registeredUsers} />
          </div>

          <div className='col-md-6'>
            <OnlineUsers stats={stats.onlineUsers} />
          </div>

          <div className='col-md-6'>
            {stats.platformStats && <Platform stats={stats.platformStats} className='h-100' />}
          </div>

          <div className='col-md-6'>
            {stats.parentStudentBranchStats && (
              <BranchWiseOnlineUsers stats={stats.parentStudentBranchStats} className='h-100' />
            )}
          </div>

          <div className='col-12'>
            <StudentParentPlatform stats={stats.parentStudentPlatformLoginStats} />
          </div>

          <div className='col-12'>
            {dailyPlatformSeries && (
              <DailyChart dailyPlatformSeries={dailyPlatformSeries} className='h-100' />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Stats
