import {toAbsoluteUrl} from '_metronic/helpers'
import {FC} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  stats: {
    students: number
    parents: number
    staff: number
    teachers: number
  }
}

const OnlineUsers: FC<Props> = ({stats}) => {
  const intl = useIntl()

  return (
    <div className='card card-flush h-md-auto'>
      <div
        className='card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center h-250px position-relative'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/pattern-2.png')})`}}
      >
        <div className='position-absolute top-0 start-0 mt-5 ms-5'>
          <span className='text-white fw-bold fs-2'>Online Users</span>
        </div>
        <h3 className='card-title align-items-center flex-column text-white'>
          <span className='fw-bolder fs-2x mb-3'>{stats.students}</span>
          <span className='text-white fw-bold fs-6'>
            {intl.formatMessage({id: 'MENU.STUDENTS'})}
          </span>
        </h3>
        <h3 className='card-title align-items-center flex-column text-white'>
          <span className='fw-bolder fs-2x mb-3'>{stats.parents}</span>
          <span className='text-white fw-bold fs-6'>
            {intl.formatMessage({id: 'MENU.PARENTS'})}
          </span>
        </h3>
        <h3 className='card-title align-items-center flex-column text-white'>
          <span className='fw-bolder fs-2x mb-3'>{stats.staff}</span>
          <span className='text-white fw-bold fs-6'>{intl.formatMessage({id: 'MENU.STAFF'})}</span>
        </h3>
        <h3 className='card-title align-items-center flex-column text-white'>
          <span className='fw-bolder fs-2x mb-3'>{stats.teachers}</span>
          <span className='text-white fw-bold fs-6'>
            {intl.formatMessage({id: 'MENU.TEACHERS'})}
          </span>
        </h3>
      </div>
    </div>
  )
}

export default OnlineUsers
