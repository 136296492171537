import {KTCard, KTCardBody, KTSVG} from '_metronic/helpers'
import {USER_TYPES_NUMERIC} from 'common/constants'
import {getDeviceTypeIcon, getDeviceTypeLabel} from 'common/helpers'
import {FC, useMemo} from 'react'
import {CardTitle, Table} from 'react-bootstrap'
import {useIntl} from 'react-intl'

type Stats = {
  device_type: number
  user_type: number
  count: number
}[]

type TableProps = {
  stats: Stats
  heading: string
}

const StatsTable: FC<TableProps> = ({stats, heading}) => {
  const intl = useIntl()
  const hasTranslation = !!intl.messages[heading]
  return (
    <KTCard>
      <KTCardBody>
        <CardTitle>{hasTranslation ? intl.$t({id: heading}) : heading}</CardTitle>

        <Table responsive hover striped bordered>
          <thead>
            <tr className='fw-bolder'>
              <th>Platform</th>
              <th className='text-end'>Total</th>
            </tr>
          </thead>
          <tbody>
            {stats.length === 0 ? (
              <tr>
                <td colSpan={2}>No data</td>
              </tr>
            ) : (
              <>
                {stats.map((stat, index: number) => (
                  <tr key={index}>
                    <td className='d-flex align-items-center'>
                      <div className='symbol symbol-45px w-40px me-5'>
                        <span className='symbol-label bg-lighten'>
                          <KTSVG
                            path={getDeviceTypeIcon(stat.device_type)}
                            className='svg-icon-1'
                          />
                        </span>
                      </div>
                      <span>{intl.$t({id: getDeviceTypeLabel(stat.device_type)})}</span>
                    </td>
                    <td className='text-end'>{stat.count}</td>
                  </tr>
                ))}

                <tr className='fw-bolder'>
                  <th scope='row'>Total</th>
                  <td className='text-end'>{stats.reduce((acc, curr) => acc + curr.count, 0)}</td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </KTCardBody>
    </KTCard>
  )
}

type Props = {
  stats: Stats
}

const StudentParentPlatform: FC<Props> = ({stats}) => {
  const studentStats = useMemo(
    () => stats.filter((stat) => stat.user_type === USER_TYPES_NUMERIC.STUDENT) as Stats,
    [stats]
  )
  const parentStats = useMemo(
    () => stats.filter((stat) => stat.user_type === USER_TYPES_NUMERIC.PARENT) as Stats,
    [stats]
  )

  return (
    <div className='row mt-5'>
      <div className='col-md-6'>
        <StatsTable heading='Students' stats={studentStats} />
      </div>
      <div className='col-md-6'>
        <StatsTable heading='Parents' stats={parentStats} />
      </div>
    </div>
  )
}

export default StudentParentPlatform
