import clsx from 'clsx'
import {ErrorMessage, Field, useField} from 'formik'
import {FC, useEffect, useMemo, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'

type Props = {
  name: string
  label?: string
  type?: string
  className?: string
  required?: boolean
  showError?: boolean
  accept?: string
  title?: string
  placeholder?: string
  helperText?: string
  readOnly?: boolean
  applyInvalidClass?: boolean
  list?: any[]
  maxLength?: number
  step?: number
  [key: string]: any
  onChange?: (value: any) => void
}

const CustomField: FC<Props> = ({
  name,
  label = '',
  type = 'text',
  className = '',
  required = false,
  showError = true,
  accept,
  title,
  placeholder,
  helperText,
  readOnly = false,
  applyInvalidClass = true,
  children,
  maxLength,
  onChange,
  step = 0.05,
  ...restProps
}) => {
  const intl = useIntl()
  const [field, meta, helpers] = useField(name)
  const [textLength, setTextLength] = useState(0)
  const id = `${name}-${Math.random()}`

  const labelHasTranslation = label ? !!intl.messages[label] : false
  const titleHasTranslation = title ? !!intl.messages[title] : false

  const renderTooltip = useMemo(() => {
    return (
      <Tooltip id={`tooltip-${id}`}>
        {titleHasTranslation ? intl.formatMessage({id: title}) : title}
      </Tooltip>
    )
  }, [title, id, intl, titleHasTranslation])

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(field.value)
    }
  }, [field.value, onChange])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value
    if (maxLength && value.length <= maxLength) {
      helpers.setValue(value)
      setTextLength(value.length)
    } else if (!maxLength) {
      helpers.setValue(value)
      setTextLength(value.length)
    }
  }

  return (
    <div className={clsx('mb-3', className)}>
      {label && (
        <label className={clsx('form-label', {required})} htmlFor={id}>
          {labelHasTranslation ? intl.formatMessage({id: label}) : label}
          {title && (
            <OverlayTrigger placement='right' overlay={renderTooltip}>
              <div className='badge pe-auto'>
                <i className='bi bi-info-circle-fill'></i>
              </div>
            </OverlayTrigger>
          )}
        </label>
      )}

      {type !== 'file' && (
        <Field
          as={type === 'select' ? 'select' : type === 'textarea' ? 'textarea' : 'input'}
          id={id}
          name={name}
          type={type === 'float' ? 'number' : type}
          placeholder={placeholder}
          step={type === 'float' ? step : undefined}
          autocomplete='off'
          aria-autocomplete='none'
          className={clsx({
            'form-control': type !== 'select',
            'form-select': type === 'select',
            'is-invalid': applyInvalidClass && meta.touched && meta.error,
            'h-60px': type === 'textarea',
          })}
          readOnly={readOnly}
          onChange={handleChange}
          value={type === 'float' ? parseFloat(field.value).toFixed(2) : field.value}
          {...restProps}
        >
          {children}
        </Field>
      )}

      {type === 'file' && (
        <Field
          id={id}
          name={name}
          type='file'
          accept={accept}
          className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
          value={undefined}
          onChange={(event: any) => {
            const files = restProps?.multiple
              ? event?.currentTarget?.files
              : event?.currentTarget?.files[0]
            helpers.setValue(files)
          }}
          readOnly={readOnly}
          {...restProps}
        />
      )}

      {type === 'textarea' && maxLength && (
        <div style={{marginTop: '10px'}}>
          {textLength}/{maxLength}
        </div>
      )}

      {helperText && (
        <small id={`${id}-help-text`} className='form-text text-muted'>
          {helperText}
        </small>
      )}

      {showError && (
        <ErrorMessage
          component='div'
          name={name}
          className={clsx('mt-2', {
            'invalid-feedback': applyInvalidClass,
            'text-danger': !applyInvalidClass,
          })}
        />
      )}
    </div>
  )
}

export default CustomField
