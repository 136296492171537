import Chart from 'react-apexcharts'
import {getCSSVariableValue} from '_metronic/assets/ts/_utils'
import {ApexOptions} from 'apexcharts'
import {FC} from 'react'
import {KTCard, KTCardBody} from '_metronic/helpers'
import {CardText, CardTitle} from 'react-bootstrap'

type Prop = {
  dailyPlatformSeries: ApexAxisChartSeries | ApexNonAxisChartSeries
} & React.HTMLAttributes<HTMLDivElement>

const getDailyPlatformChartOptions = (): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-light-info')

  return {
    chart: {
      fontFamily: 'inherit',
      toolbar: {
        show: true,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: (val) => val.toLocaleString(),
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}

const DailyChart: FC<Prop> = ({dailyPlatformSeries, ...props}) => {
  return (
    <KTCard {...props}>
      <KTCardBody>
        <CardTitle>
          <CardText>Daily Login Stats</CardText>
        </CardTitle>
        <Chart
          options={getDailyPlatformChartOptions()}
          series={dailyPlatformSeries}
          type='line'
          height='300'
        />
      </KTCardBody>
    </KTCard>
  )
}

export default DailyChart
